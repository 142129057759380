<template>
  <div class="video-object" :style="videoObjectSize">
    <video ref="videoObject" width="100%" height="100%" autoplay playsinline muted></video>
  </div>
</template>

<script>
import webrtcadapter from './webrtcadapter.js';
// import HiVeWebRtcPlayer from 'HiVeWebRtcPlayer.js'

export default {
  props: {
    module_id: {},
    server_ip: {},
    server_port: {},
    video_name: {},
    video_port: {},
    autoplay: {
      type: Boolean,
      default: true,
    },
    videoWidth: {},
    videoHeight: {},
  },
  data() {
    return {
      serverIp: '',
      serverPort: 0,
      videoName: '',
      pc: null,
      ws: null,
      state: -1,
      latest_stop_time: 0,
      is_connected: false,
      video_module: null,
      show_controls: null,
      media_port: 0,
    };
  },
  computed: {
    videoObjectSize() {
      let style = {
        width: '100%',
        height: '100%',
      };
      if (this.videoWidth) {
        style.width = this.videoWidth + 'px';
      }
      if (this.videoHeight) {
        style.height = this.videoHeight + 'px';
      }

      return style;
    },
  },
  created() {},
  mounted() {
    this.serverIp = this.server_ip;
    this.serverPort = this.server_port;
    this.videoName = this.video_name;

    this.$nextTick(() => {
      this.video_module = this.$refs['videoObject'];
      this.show_controls = this.video_module.controls;
      this.video_module.onplay = this.play;
      this.video_module.onpause = this.stop;
      this.video_module.onloadedmetadata = (e) => {};
      this.media_port = this.video_port;

      if (this.autoplay && this.serverIp !== '') this.play();

      this.$emit('onloadWebrtcPlayer', {});
    });
  },
  methods: {
    play(param) {
      var cur_time = new Date().getTime();
      if (param) {
        this.serverIp = param.ip || '';
        this.serverPort = param.port || '';
        this.videoName = param.videoName || '';
      }
      // if ((this.state == -1) && (cur_time - this.latest_stop_time > 1000)) {
      if (this.state == -1) {
        this.video_module.srcObject = null;
        this.state = 0;
        this.is_connected = false;
        this.video_module.setAttribute('style', 'background: black url(loader.gif) center no-repeat;');
        this.show_controls = this.video_module.controls;
        this.video_module.controls = false;
        this.doSignaling();
      }

      // if (cur_time - this.latest_stop_time <= 1000)
      //     this.video_module.pause();
    },

    stop() {
      this.show_controls = this.video_module.controls;
      this.terminate();
    },

    terminate() {
      this.latest_stop_time = new Date().getTime();
      this.state = -1;
      this.video_module.setAttribute('style', 'background-color:black');

      if (this.show_controls) this.video_module.controls = true;

      if (this.pc != null) {
        this.pc.onconnectionstatechange = null;
        this.pc.close();
        this.pc = null;
      }

      if (this.ws != null) {
        this.ws.onerror = null;
        this.ws.close();
        this.ws = null;
      }

      this.is_connected = false;

      this.video_module.pause();
    },
    onCreateOfferSuccess(desc) {
      desc.sdp = this.setCodec(desc.sdp, 'video', 'H264', 90000);

      //Fix for some browsers and/or adapter incorrect behavior
      desc.sdp = desc.sdp.replace('a=sendrecv', 'a=recvonly');
      desc.sdp = desc.sdp.replace('a=sendrecv', 'a=recvonly');

      var json_msg = desc.toJSON();
      json_msg['mediaport'] = this.media_port;

      //Signal the SDP to the server
      this.ws.send(JSON.stringify(json_msg));

      this.pc.setLocalDescription(desc).then(
        function () {
          console.log('setLocalDescription Succeess');
        },
        function () {
          this.terminate();
          console.error('setLocalDescription Error :' + error.toString());
        }
      );
    },
    doSignaling() {
      var offer_url = 'ws://' + this.serverIp + ':' + this.serverPort + '/hive_api/webrtc_stream/' + this.videoName;
      let _this = this;
      try {
        this.ws = new WebSocket(offer_url);
      } catch (error) {
        this.terminate();
        console.error('Error creating websocket: ' + error);
      }

      this.ws.onmessage = (evt) => {
        var response = evt.data;
        var json_obj = JSON.parse(response);
        _this.is_connected = true;

        if (json_obj.result == 200) {
          if (json_obj.type == 'join') {
            var servers = null;
            var offerOptions = { offerToReceiveAudio: 0, offerToReceiveVideo: 1 };

            _this.pc = new RTCPeerConnection(servers);
            _this.pc.onicecandidate = (e) => {};
            _this.pc.onconnectionstatechange = (e) => {
              if (_this.pc.connectionState === 'failed') {
                _this.terminate();
                console.error('Connection failed - playback stop');
              }
            };
            _this.pc.ontrack = (e) => {
              _this.video_module.srcObject = e.streams[0];
              _this.video_module.setAttribute('style', 'background-color:black');
              _this.video_module.setAttribute('object-fit', 'fill');

              if (_this.show_controls) _this.video_module.controls = true;
            };

            _this.pc.createOffer(offerOptions).then(
              (desc) => {
                desc.sdp = _this.setCodec(desc.sdp, 'video', 'H264', 90000);

                //Fix for some browsers and/or adapter incorrect behavior
                desc.sdp = desc.sdp.replace('a=sendrecv', 'a=recvonly');
                desc.sdp = desc.sdp.replace('a=sendrecv', 'a=recvonly');

                var json_msg = desc.toJSON();
                json_msg['mediaport'] = _this.media_port;

                //Signal the SDP to the server
                _this.ws.send(JSON.stringify(json_msg));

                _this.pc.setLocalDescription(desc).then(
                  function () {
                    console.log('setLocalDescription Succeess');
                  },
                  function () {
                    _this.terminate();
                    console.error('setLocalDescription Error :' + error.toString());
                  }
                );
              },
              () => {
                _this.terminate();
                console.error('createOffer Error :' + error.toString());
              }
            );
          } else if (json_obj.type == 'answer') {
            var server_sdp = json_obj.sdp;
            var server_endpoint = json_obj.endpoint;

            server_endpoint.candidate = this.ensureValidCandidate(server_endpoint.candidate);

            _this.pc.setRemoteDescription(new RTCSessionDescription({ type: 'answer', sdp: server_sdp }));
            var candidate = new RTCIceCandidate({ sdpMLineIndex: server_endpoint.sdpMLineIndex, candidate: server_endpoint.candidate });
            _this.pc.addIceCandidate(candidate);
          }
        } else {
          this.terminate();
          console.error(response);
        }
      };

      this.ws.onerror = function (evt) {
        if (!_this.is_connected) {
          if (this.terminate) this.terminate();

          console.error('Connecting error:', evt);
        }
      };
    },
    ensureValidCandidate(candidate) {
      if (candidate.search(this.server_ip) !== -1 || this.server_ip == '127.0.0.1' || !this.validateIPaddress(this.server_ip)) {
        return candidate;
      }

      //In case the server is behind the NAT router, replace private IP with public IP in the candidate
      var candLines = candidate.split(' ');
      var ipIndex = 4;
      for (var i = 0; i < candLines.length; i++) {
        if (candLines[i] === 'typ') {
          ipIndex = i - 2;
          break;
        }
      }

      candLines[ipIndex] = this.server_ip;
      candidate = candLines.join(' ');
      return candidate;
    },
    validateIPaddress(ipaddr) {
      if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          ipaddr
        )
      ) {
        return true;
      }

      return false;
    },

    setCodec(sdp, type, codec, clockRate) {
      function extractPayloadType(sdpLine, pattern) {
        var result = sdpLine.match(pattern);
        return result && result.length == 2 ? result[1] : null;
      }

      function EnsureSupportedProfile(codec, sdpLines, mLineIndex, codecPayload) {
        if (codec != 'H264') return true;

        //Server can send any profile/level H264, but SDP has to specify supported one
        for (var i = mLineIndex; i < sdpLines.length; i++) {
          if (sdpLines[i].search('a=fmtp:' + codecPayload) === 0 && sdpLines[i].search('profile-level-id=42') !== -1) return true;
        }

        return false;
      }

      function setDefaultCodec(mLine, payload) {
        var elements = mLine.split(' ');
        var newLine = new Array();
        var index = 0;
        for (var i = 0; i < elements.length; i++) {
          if (index === 3) {
            newLine[index++] = payload;
            break;
          }
          if (elements[i] !== payload) newLine[index++] = elements[i];
        }
        return newLine.join(' ');
      }

      var sdpLines = sdp.split('\r\n');

      for (var i = 0; i < sdpLines.length; i++) {
        if (sdpLines[i].search('m=' + type) !== -1) {
          var mLineIndex = i;
          break;
        }
      }

      if (mLineIndex === null) return sdp;

      var codecPayload = null;
      var re = new RegExp(':(\\d+) ' + codec + '\/' + clockRate);

      for (var i = mLineIndex; i < sdpLines.length; i++) {
        if (sdpLines[i].search(codec + '/' + clockRate) !== -1) {
          codecPayload = extractPayloadType(sdpLines[i], re);
          if (codecPayload && EnsureSupportedProfile(codec, sdpLines, mLineIndex, codecPayload)) {
            sdpLines[mLineIndex] = setDefaultCodec(sdpLines[mLineIndex], codecPayload);
            break;
          }
        }
      }

      if (codecPayload === null) return sdp;

      var rtmpmap = 'a=rtpmap:';
      var rtcp = 'a=rtcp-fb:';
      var fmptp = 'a=fmtp:';
      var rtmpmapThis = 'a=rtpmap:' + codecPayload;
      var rtcpThis = 'a=rtcp-fb:' + codecPayload;
      var fmptpThis = 'a=fmtp:' + codecPayload;
      var bAddAll = false;
      var resSDPLines = new Array();

      for (var i = 0; i < sdpLines.length; i++) {
        if (i <= mLineIndex) {
          resSDPLines.push(sdpLines[i]);
        } else {
          if (sdpLines[i].search('m=') === 0) bAddAll = true;

          var bNotToAdd =
            (sdpLines[i].search(rtmpmap) === 0 && sdpLines[i].search(rtmpmapThis) !== 0) ||
            (sdpLines[i].search(rtcp) === 0 && sdpLines[i].search(rtcpThis) !== 0) ||
            (sdpLines[i].search(fmptp) === 0 && sdpLines[i].search(fmptpThis) !== 0);

          if (bAddAll || !bNotToAdd) resSDPLines.push(sdpLines[i]);
        }
      }

      sdp = resSDPLines.join('\r\n');
      return sdp;
    },
    screenValue() {
      let width = this.video_module.clientWidth;
      let height = this.video_module.clientHeight;
      var param = {
        camerawidth: width,
        cameraheight: height,
      };
      return param;
    },
  },
};
</script>

<style>
.video-object {
  background-color: #000;
  height: 100%;
  text-align: center;
  width: 100%;
}
</style>
