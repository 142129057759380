var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-object", style: _vm.videoObjectSize },
    [
      _c("video", {
        ref: "videoObject",
        attrs: {
          width: "100%",
          height: "100%",
          autoplay: "",
          playsinline: "",
          muted: ""
        },
        domProps: { muted: true }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }